import React, { useRef } from 'react'
import { RiDoubleQuotesR } from "react-icons/ri";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import  { FreeMode, Pagination, Navigation } from "swiper";
import data from "../testimonial.json"

const Testimonial = () => {
    const swiperRef5 = useRef();

  return (
    <>
     <section className="featured-properties-area section-padding-100-50 testimonial" style={{padding: "15px 0"}}>
        <div className="container">
          <div className="row heading-row">
            <div className="col-12">
            <div className="section-heading wow fadeInUp">
                <h2>Testimonial</h2>
              </div>
            </div>
          </div>
          <div className="row" style={{marginTop: "-10px"}}>
            
                  <Swiper
                  slidesPerView="auto"
               spaceBetween={20}
               freeMode={true}
               navigation={false} 
               pagination={{
                 clickable: true,
               }}
               modules={[FreeMode, Pagination, Navigation]}
               onBeforeInit={(swiper) => {
                 swiperRef5.current = swiper;
               }}
               className="mySwiper"
               breakpoints={{
                 // When window width is >= 640px
                 640: {
                   slidesPerView: 1,
                   spaceBetween: 20
                 },
                 // When window width is >= 768px
                 768: {
                   slidesPerView: 2,
                   spaceBetween: 20
                 },
                 // When window width is >= 1024px
                 1024: {
                   slidesPerView: 4,
                   spaceBetween: 20
                 }
               }}
             >
               {
              data.testimonial.map((r) => {
                return (
                 
                   <div className="col-12 col-md-6 col-xl-4 client-section" key={r.id}>
                   <SwiperSlide>
                     <div className="testi_wrapper pt-5 ps-1 pe-1">
                       <div
                         className="single-featured-property mb-50 wow fadeInUp client"
                         data-wow-delay="100ms"
                         style={{height: "46vh",  backgroundColor: '#f1f6f8'}}
                         >
       
                           <span className="quotes">
                         {" "}
                         <RiDoubleQuotesR />{" "}
                       </span>
                       <div className="property-content">
                         <p>
                        {r.description}
                         </p>
                         <h5> {r.name}</h5>
                         
                       </div>
                       </div>
                     </div>
                   </SwiperSlide>
                   </div>
                       )
                      })
                    }
                  </Swiper>
         
          </div>
        </div>
      </section>
    
    </>
  )
}

export default Testimonial