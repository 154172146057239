import React, { useState } from "react";
import { FaPhone } from "react-icons/fa";

const ContactForm = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState(''); 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a data object with the form values
    const data = {
      name,
      email,
      phone,
      desc: description
    };
    try {
     
      const response = await fetch('https://growmaxrealty.in/administration/api/user/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      
      if (response.ok) {
        setName('');
        setPhone('');
        setEmail('');
        setDescription('');
      } else {
        
        alert('Failed to Send Message');
      }
    } catch (error) {
    
      alert('Network error:', error);
    }
  };
 

  return (
    <>
      <div className="realtor-info">
        <div className="realtor---info">
          <h2>Price on Request</h2>
          <li>
            <a href="tel: +91 8750994488">
              <FaPhone /> +91 8750994488
            </a>
          </li>
        </div>
        <div className="realtor--contact-form">
          <form action="#" method="post" onSubmit={handleSubmit}>
            <div className="form-group">
            
              <input
                type="text"
                className="form-control"
                id="realtor-name"
                placeholder="Your Name"
                name="name"
                value={name}
                      onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
            
              <input
                type="number"
                className="form-control"
                id="realtor-number"
                placeholder="Your Number"
                name="phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div className="form-group">
             
              <input
                type="enumber"
                className="form-control"
                id="realtor-email"
                placeholder="Your Mail"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="form-group">
            
              <textarea
                name="message"
                className="form-control"
                id="realtor-message"
                cols="30"
                rows="10"
                placeholder="Your Message"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></textarea>
            </div>
            <button type="submit" className="btn south-btn" style={{borderRadius: "5px", maxWidth: "150px"}}>
              Send Message
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ContactForm;
