import React from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from '../../img/bg-img/hero1.jpg'
import logo from "../../img/core-img/logo.png";


const About = () => {
  return (
    <>
    <Caller/>
    <Scroll/>
    <Navbar/>
    <section className="featured">
        <div className="banner">
          <div className="pic1" >
            {/* <img src={"https://growmaxrealty.in/alpha/administration/" + slider.banner_image} alt="banner"/> */}
            <img src={banner} alt="" />
            <h2>About GrowMax</h2>
          </div>
        </div>
      </section>
      <section className="south-editor-area featured-properties-area section-padding-100-50 about">
        <div className="container">
              <div className="about-section">
                
                <p><h3>About Work</h3> Our diverse portfolio encompasses a wide range of residential, commercial, and industrial projects, showcasing our expertise in creating exceptional spaces. From custom-built homes and luxurious apartments to innovative office buildings and industrial facilities, our meticulous craftsmanship and attention to detail shine through in every project we undertake. We also excel in renovation and restoration endeavors, breathing new life into existing structures while preserving their historical significance. With a commitment to excellence and a focus on sustainable practices, we contribute to the development of vital infrastructure projects that benefit communities.</p>
                <div className="about-img">
                    <img src={logo} alt=""  />
                    </div>
                 </div>
                 <p><h3>Together we create solutions</h3> At GrowMax, we firmly believe that collaboration is the key to success. We understand that no challenge is insurmountable when we join forces and work together. With a shared vision and collective expertise, we harness the power of teamwork to create innovative and effective solutions for our clients. Our collaborative approach fosters creativity, encourages diverse perspectives, and brings out the best in each team member. By combining our knowledge, skills, and passion, we embark on a journey of problem-solving, pushing boundaries, and delivering transformative results. Together, we are not just building structures; we are building relationships, trust, and a brighter future.</p>
                 </div>
      </section>
     
    <Footer/>
    </>
  )
}

export default About