import React, { useEffect, useState } from "react";
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { FaAngleRight } from "react-icons/fa";
import {url, eventUrl, imgUrl2, imageUrl } from "../../data/Data";
import axios from "axios";

const Events = () => {
  const [eventData, setEventData] = useState([])

  const getEvent = () => {
    axios.get(`${url}${eventUrl}`).then((res) => {
      if (res.data.status === 200) {
      setEventData(res?.data?.data)
      }
    })
  }

  useEffect(() => {
    getEvent()
  }, [])

  return (
    <>
      <Caller />
      <Scroll />
      <Navbar />
      <section
        className=" section-padding-100 featured"
        style={{ height: "auto" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="listings-top-meta d-flex justify-content-between mb-100">
                <div className="view-area d-flex align-items-center" style={{marginTop: "40px"}}>
                  <span>
                   <a href="/"> Home </a><FaAngleRight /> Projects <FaAngleRight /> Events
                  </span>
                  </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="events  active-tab">
              {
                eventData && eventData.map((result) => {
                  const date = result.created_at;
                  const newdate = new Date(date);
                  const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;
                  return (
            <div className="col-12 col-md-6 col-xl-4 justify-content-center" key={result.id}>
                <div
                  className="single-featured-property mb-50 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <div className="property-thumb">

                  <img src={imageUrl + imgUrl2 + result.eventcoverimage.file_name} alt="Gallery" />
                    <p className="events-heading">{result.event_name}</p>
                    <div className="date">
                      <span style={{fontSize: "18px"}}>{formattedDate}</span>
                    </div>
                    <div className="shadow"></div>
                  </div>
                </div>
              </div>
                 
                 )
                })
              }
            </div>
            </div>
          </div>
      </section>
      <Footer />
    </>
  );
};

export default Events;
