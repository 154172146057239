import React from "react";
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from "../../img/bg-img/hero1.jpg";
import { motion, animate } from "framer-motion";
import { useRef } from "react";


const AboutDetail = () => {
const yearCount = useRef(null)
const projectCount = useRef(null)
const facesCount = useRef(null)
const clientCount = useRef(null)

const animateYearCount = () => {
    animate(0, 12, {
        duration: 1,
        onUpdate: (v) => {
          if (yearCount.current) {
              yearCount.current.textContent = v.toFixed();
          }
      }
    })
}
const animateProjectCount = () => {
    animate(0, 8000, {
        duration: 1,
        onUpdate: (v) => {
          if (projectCount.current) {
            projectCount.current.textContent = v.toFixed()
          }
        }
    })
}
const animateFaceCount = () => {
    animate(0, 5000, {
        duration: 1,
        onUpdate: (v) => {
          if(facesCount.current){
            facesCount.current.textContent = v.toFixed()
          }
        }
    })
}
const animateClientCount = () => {
    animate(0, 5000, {
        duration: 1,
        onUpdate: (v) => {
         if(clientCount.current){
          clientCount.current.textContent = v.toFixed()
         }
        }
    })
}



  return (
    <>
      <Caller />
      <Scroll />
      <Navbar />
      <section className="featured">
        <div className="banner">
          <div className="pic1">
            {/* <img src={"https://growmaxrealty.in/alpha/administration/" + slider.banner_image} alt="banner"/> */}
            <img src={banner} alt="" />
            <h2>About GrowMax</h2>
          </div>
        </div>
      </section>
      <section className="south-editor-area featured-properties-area section-padding-100-50 about">
        <div className="container">
          <div className="about-detail">
            <p><h3>Who We Are?</h3> <strong>GrowMax,</strong> <br />We pride ourselves on being a leading construction company dedicated to delivering exceptional results and exceeding our clients' expectations. With years of experience in the industry, we have built a solid reputation for delivering high-quality projects on time and within budget.Collaboration lies at the heart of our approach. We understand that successful projects are the result of effective communication and strong partnerships. We work closely with our clients, architects, and subcontractors to foster an environment of transparency, ensuring that everyone involved is on the same page throughout the construction process. As a customer-centric company, we place great emphasis on client satisfaction. We believe in building lasting relationships with our clients based on trust, integrity, and open communication. We take the time to understand our clients' needs, working closely with them to deliver results that surpass their expectations.
            </p>
          </div>
          <div className="about-area">
            <div className="years">
                <p>
            <motion.span
            ref={yearCount}
            whileInView={animateYearCount}
            ></motion.span> + </p>
           <span className="tags"> years of experience</span>
            </div>
            <div className="years">
                <p>
            <motion.span
            ref={projectCount}
            whileInView={animateProjectCount}
            ></motion.span> + </p>
           <span className="tags"> Project done</span>
            </div>
            <div className="years">
                <p>
            <motion.span
            ref={facesCount}
            whileInView={animateFaceCount}
            ></motion.span> + </p>
           <span className="tags"> Happy faces</span>
            </div>
            <div className="years">
                <p>
            <motion.span
            ref={clientCount}
            whileInView={animateClientCount}
            ></motion.span> +</p>
           <span className="tags"> Clients</span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutDetail;
