import React, { useEffect, useState } from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import { ImLocation, ImEnlarge } from "react-icons/im";
import { FaBed, FaArrowRight, FaAngleRight } from "react-icons/fa";
import { url, cityDataUrl, imgUrl, imageUrl, bannerUrl} from '../../data/Data'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import  { Pagination, Navigation, Autoplay } from "swiper";
import { LazyLoadImage } from "react-lazy-load-image-component";


const City = () => {
  const [slider, setSlider] = useState([]);
const [cityDataInfo, setCityDataInfo] = useState([])
const {id} = useParams()
const [sortBy, setSortBy] = useState('default');
  const [isFilterEnabled, setIsFilterEnabled] = useState(false);

const getData = () => {
  axios.get(`${url}${bannerUrl}`).then((res) => {
    setSlider(res?.data?.data);
  });
};

const getCityData = () => {
  let cityDataUrlWithSort = `${cityDataUrl}/${id}`;
  if (isFilterEnabled) {
    cityDataUrlWithSort += `?sortBy=created_at`;
  }

  axios.get(`${url}${cityDataUrlWithSort}`).then((res) => {
    if (res.data.status === 200) {
      setCityDataInfo(res?.data?.data);
    }
  });
};

const handleSortChange = (event) => {
  const selectedOption = event.target.value;

  if (selectedOption === 'default') {
    setIsFilterEnabled(false);
    setSortBy(null);
  } else {
    setIsFilterEnabled(true);
    setSortBy(selectedOption);
  }
};

useEffect(() => {
  getData();
  getCityData();
}, [id, isFilterEnabled])

const truncate = (text, maxLength) => {
  if (text == null) {
    return null;
  } else if (text.length > maxLength) {
    const words = text.split(" ");
    return words.slice(0, maxLength).join(" ");
  } else {
    return text;
  }
};

  return (
    <>
    <Caller/>
    <Scroll/>
    <Navbar/>
    <section className="banner-section">
        <div className="banner">
        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          freeMode={true}
          navigation={false} 
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
    {slider && slider.map((result) => {
            return (
              <SwiperSlide key={result.id} >
          <div className="pic1">
            
      <LazyLoadImage src={imageUrl + result.slider_image} alt="Banner"/>
      {cityDataInfo && cityDataInfo.slice(0, 1).map((result) => {
  return (
<h2> {result.cityinfo && result.cityinfo.city_name} </h2>
)})}
             
      </div>
      </SwiperSlide>
            )
          })}
          </Swiper>
        </div>
      </section>

<section className=" section-padding-100 featured" style={{height: "auto"}}>
<div className="container">
<div className="row">
<div className="col-12">
<div className="listings-top-meta d-flex justify-content-between mb-100">
<div className="view-area d-flex align-items-center" style={{minWidth: "20vw"}}>
<span className='d-flex align-items-center' style={{minWidth: "20vw", flexWrap: "wrap"}}><Link to="/">Home</Link> <FaAngleRight/> City  <FaAngleRight/> 
{cityDataInfo && cityDataInfo.slice(0, 1).map((result) => {
  return (
<span> {result.cityinfo && result.cityinfo.city_name} </span>
)})}
</span>
<div className="grid_view ml-15"><a href="#" className="active"><i className="fa fa-th" aria-hidden="true"></i></a></div>
<div className="list_view ml-15"><a href="#"><i className="fa fa-th-list" aria-hidden="true"></i></a></div>
</div>
<div className="order-by-area d-flex align-items-center">
<span className="mr-15">Order by:</span>
<select value={sortBy} onChange={handleSortChange}>
<option value="default">Default</option>
<option value="newest">Newest</option>
<option value="oldest">Oldest</option>
</select>
</div>

</div>
</div>
</div>
<div className="row">
{cityDataInfo && cityDataInfo.sort((a, b) => {
    if (sortBy === 'newest') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else if (sortBy === 'oldest') {
      return new Date(a.created_at) - new Date(b.created_at);
    } else {
      return 0;
    }
  }).map((result) => {
               // Calculting Maximum and Minimum BHK
               const getMinMaxBhk = () => {
                const bhkResult = result.space.map((space) =>
                  parseInt(space.bhk)
                );
                const filteredBhk = bhkResult.filter(
                  (bhk) => bhk !== null && !isNaN(bhk)
                );

                if (filteredBhk.length === 0) {
                  return null;
                }

                if (filteredBhk.length === 1) {
                  return filteredBhk[0];
                }

                const minBhk = Math.min(...filteredBhk);
                const maxBhk = Math.max(...filteredBhk);
                return `${minBhk} / ${maxBhk}`;
              };

              // Calculting Maximum and Minimum Area
              const getMinMaxSize = () => {
                const areaValue = result.space.map((space) =>
                  parseInt(space.size)
                );
                const filteredSize = areaValue.filter((space) => space !== null);
                if (filteredSize.length === 0) {
                  return { size: "" }
                }
                else if (filteredSize.length === 1){
                  return { size: filteredSize[0] }
                }
                else {
                  const minSize = Math.min(...filteredSize);
                  const maxSize = Math.max(...filteredSize);
                  return { minSize, maxSize };
                }
                }
                 const { minSize, maxSize, size } =  getMinMaxSize()

              // Calculting Maximum and Minimum Price
              const getMinMaxPrice = () => {
                const prices = result.space.map((space) => space.price);
                const filteredPrice = prices.filter((price) => price !== null);
                if (filteredPrice.length === 0) {
                  return { price: "" };
                } else if (filteredPrice.length === 1) {
                  return { price: filteredPrice[0] };
                } else {
                  const minPrice = Math.min(...filteredPrice);
                  const maxPrice = Math.max(...filteredPrice);
                  return { minPrice, maxPrice };
                }
              };
              const { minPrice, maxPrice, price } = getMinMaxPrice();
            return (
    <div className="col-12 col-md-6 col-xl-4" key={result.id}>
      <Link to={`/project/detail/${result.id}`}>
      <div className=' details-area'>
    <div className="single-featured-property mb-50 wow fadeInUp" data-wow-delay="100ms">
    
    <div className="property-thumb">
        {result.projectimage.slice(0, 1).map((i) => (
    <img key={i.id} src={ imgUrl + i.proimage} alt=""/>
        ))}
        { result.sale_type && (
          <div className="tag">
    <span>{result && result.sale_type}</span>
    </div>
    
        )}
    
    </div>
    
    <div className="property-content">
                  <h5>{truncate(result.name, 3)}</h5>
                  <p className="location">{(result.developerinfo ?( result.developerinfo.name) : "")}</p>
                  <div className="property-meta-data d-flex listing">
                    <div>
                    <div className="garage" style={{ marginBottom: " 4px", color: "#000" }}>
                      <FaBed />
                      {getMinMaxBhk && <span>{getMinMaxBhk()} BHK</span>}
                    </div>
                    <div className="space" style={{ color: "#000" }}>
                      <ImEnlarge />
                      <span> {minSize && maxSize ? ` ${minSize} - ${maxSize} Sqft` : `${size} sqft`}</span>
                    </div>
                    </div>
                    <div className="list-price highlight">
                        <p>
                          ₹{" "}
                          <span>
                            {minPrice && maxPrice
                              ? `${
                                  minPrice >= 10000000
                                    ? (minPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (minPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                } - ${
                                  maxPrice >= 10000000
                                    ? (maxPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (maxPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                }`
                              : price >= 10000000
                              ? (price / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (price / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " L"}
                          </span>
                          *
                        </p>
                      </div>
                  </div>
                  <div className="view-location">
                    <p className="location location1" style={{height: "7.6vh"}}>
                      <ImLocation />  {result.locationinfo && result.locationinfo.name},  {result.cityinfo && result.cityinfo.city_name}
                    </p>
                    <Link to={`/project/detail/${result.id}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
    </div>
    </div>
    </Link>
    </div>
            )
        })}
            
</div>

</div>
</section>
    <Footer/>
    </>
  )
}

export default City