import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './components/style.css'
import Home from './components/Home';
import Featured from './components/pages/Featured';
import Residential from './components/pages/Residential';
import Commercial from './components/pages/Commercial';
import Blogs from './components/pages/Blogs';
import Client from './components/pages/Client';
import Contact from './components/pages/Contact';
import City from './components/pages/City';
import Builder from './components/pages/Builder';
import ProjectDetails from './components/pages/ProjectDetails';
import About from './components/pages/About';
import Mission from './components/pages/Mission';
import AboutDetail from './components/pages/AboutDetail';
import BlogDetails from './components/pages/BlogDetails';
import Events from './components/pages/Events';
import PageChange from './components/PageChange';
import Privacy from './components/pages/Privacy';
import Terms from './components/pages/Terms';
import Gallery from './components/pages/Gallery';
import Media from './components/pages/Media';
import Floor from './components/MicrositePage/Floor';
import Price from './components/MicrositePage/Price';
import Layout from './components/MicrositePage/Layout';
import Location from './components/MicrositePage/Location';


function App() {
  return (
   <>
   <BrowserRouter>
   <PageChange/>
   <Routes>
    <Route path="/" exact element={<Home/>}/>
    <Route path='/featured' element={<Featured/>}/>
    <Route path='/residential' element={<Residential/>}/>
    <Route path='/commercial' element={<Commercial/>}/>
    <Route path='/blogs' element={<Blogs/>}/>
    <Route path='/client' element={<Client/>}/>
    <Route path='/contact' element={<Contact/>}/>
    <Route path='/city/:city/:id' element={<City/>}/>
    <Route path='/builder/:builder/:id' element={<Builder/>}/>
    <Route path='/project/detail/:id' element={<ProjectDetails/>}/>
    <Route path='/about' element={<About/>}/>
    <Route path='/mission' element={<Mission/>}/>
    <Route path='/aboutdetail' element={<AboutDetail/>}/>
    <Route path='/blog/detail/:slug' element={<BlogDetails/>}/>
    <Route path='/events' element={<Events/>}/>
    <Route path='/privacy' element={<Privacy/>}/>
    <Route path='/terms' element={<Terms/>}/>
    <Route path='/event/detail/:id' element={<Gallery/>} />
    <Route path='/media' element={<Media/>} />
    <Route path='/project/pagedetail/:id/floorplan' element={<Floor/>}/>
    <Route path='/project/pagedetail/:id/price' element={<Price/>}/>
    <Route path='/project/pagedetail/:id/layout-plan' element={<Layout/>}/>
    <Route path='/project/pagedetail/:id/location' element={<Location/>} />

   </Routes>
   </BrowserRouter>
   </>
  );
}

export default App;

