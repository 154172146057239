import React from "react";
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import img1 from "../../img/bg-img/feature1.jpg";
import { FaAngleRight } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import { url, blogdetails, imgUrl } from "../../data/Data";
import axios from "axios";
import { useEffect } from "react";

const BlogDetails = () => {
const [blogDetails, setBlogDetails] = useState([])
const [otherBlog, setOtherBlog] = useState([])
const {  slug } = useParams()

const getBlog = () => { axios.get(`${url}${blogdetails}/${slug}`)
.then((res) => {
  setBlogDetails(res?.data?.data)
  setOtherBlog(res?.data?.others)
  
})
}

useEffect(() => {
  getBlog()
}, [slug])

const truncate = (text, maxLength) => {
  if (text == null) {
    return null;
  } else if (text.length > maxLength) {
    const words = text.split(" ");
    return words.slice(0, maxLength).join(" ");
  } else {
    return text;
  }
};

function createMarkup(content) {
  return { __html: content };
}

  return (
    <>
      <Caller />
      <Scroll />
      <Navbar />

      <section className="south-editor-area featured-properties-area blogdetails">
        <div className="listings-top-meta d-flex justify-content-between mb-20">
          <div className="view-area d-flex align-items-center">
            <span>
              <Link to="/">Home</Link> <FaAngleRight />{" "}
              Blog Details
            </span>
          </div>
        </div>
        <div className="container blog-details">
          <div className="right card">
            {otherBlog.result ? otherBlog.map((result) => (
          <img key={result.id} src={imgUrl + result.coverimage} alt="" />
            )) :  <img src={img1} alt="Blog" />}
           
            <div className="card-body">
              <h5>{blogDetails && blogDetails.title}</h5>

              {blogDetails?.blogimage  && blogDetails?.blogimage.map((result) => {
                const date = result.created_at;
                const newdate = new Date(date);
                const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;
                return (
                  <span key={result.id}>{formattedDate}</span>
                )
              })}
              
              <p dangerouslySetInnerHTML={createMarkup(blogDetails.description)}></p>
            </div>
          </div>
          <div className="left card">
            <h4>Recent Blogs</h4>
            <hr />
            <div className="row">
              {
                otherBlog?.map((result) => {
                  return (
                    <div className="blog-side"  key={result.id}>
                      {result.coverimage ? 

                    <img src={imgUrl + result.coverimage} alt="" />
                    :   <img src={img1} alt="" /> }
                   
                    <div className="left-part">
                      <Link to={`/blog/detail/${result.slug}`}>
                        <h6>{truncate(result.title, 3)}</h6>
                      </Link>
                      {result.blogimage.map((i) => {
                         const date = i.created_at;
                         const newdate = new Date(date);
                         const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;
                         return (
                      <span key={i.id}>{formattedDate}</span>
                         )
                })}
                    </div>
                   
                  </div>
            )
          })
        }
        </div>
       
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default BlogDetails;
