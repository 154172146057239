import React from "react";
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from "../../img/bg-img/hero1.jpg";
import { FaAngleRight } from "react-icons/fa";
import { RiDoubleQuotesR } from "react-icons/ri";
import { Link } from "react-router-dom";

const Client = () => {
  return (
    <>
      <Caller />
      <Scroll />
      <Navbar />
      <section className="featured">
        <div className="banner">
          <div className="pic1">
            <img src={banner} alt="banner" />
            <h2>Client's Speak</h2>
          </div>
        </div>
      </section>
      <section className="featured-properties-area section-padding-100-50">
        <div className="container">
          <div className="row heading-row">
            <div className="col-12">
            <div className="listings-top-meta d-flex justify-content-between mb-20">
          <div className="view-area d-flex align-items-center">
            <span>
              <Link to="/">Home</Link> <FaAngleRight />{" "}
              Client's Speak
            </span>
          </div>
        </div>
            </div>
          </div>
          <div className="row" style={{marginTop: "25px"}}>
            <div className="col-12 col-md-6 col-xl-4 client-section">
              <span>
                {" "}
                <RiDoubleQuotesR />{" "}
              </span>
              <div
                className="single-featured-property mb-50 wow fadeInUp client"
                data-wow-delay="100ms"
                style={{height: "46vh",  backgroundColor: '#f1f6f8'}}
              >
                <div className="property-content" >
                  <p>
                  "Working with GrowMax has been an exceptional experience. From the initial consultation to the final delivery, their team demonstrated utmost professionalism and expertise. 
                  </p>
                  <h5>  Siddharth Sharma</h5>
                 
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 client-section">
              <span>
                {" "}
                <RiDoubleQuotesR />{" "}
              </span>
              <div
                className="single-featured-property mb-50 wow fadeInUp client"
                data-wow-delay="100ms"
                style={{height: "46vh",  backgroundColor: '#f1f6f8'}}
              >
                <div className="property-content" >
                  <p>
                  The level of attention and dedication shown by GrowMax was impressive. Their seamless communication and prompt responses made the entire process smooth and efficient.
                  </p>
                  <h5> Rajesh Gupta</h5>
                  
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 client-section">
              <span>
                {" "}
                <RiDoubleQuotesR />{" "}
              </span>
              <div
                className="single-featured-property mb-50 wow fadeInUp client"
                data-wow-delay="100ms"
                style={{height: "46vh",  backgroundColor: '#f1f6f8'}}
              >
                <div className="property-content" >
                  <p>
                  We are delighted with the end product and the impact it has made on our business. GrowMax has proven to be a reliable and trustworthy partner, and we highly recommend their services.
                  </p>
                  <h5>  Karan Singh</h5>
                 
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-4 client-section">
              <span>
                {" "}
                <RiDoubleQuotesR />{" "}
              </span>
              <div
                className="single-featured-property mb-50 wow fadeInUp client"
                data-wow-delay="100ms"
                style={{height: "46vh",  backgroundColor: '#f1f6f8'}}
              >
                <div className="property-content" >
                  <p>
                  GrowMax deliver a high-quality solution, but they also provided valuable insights and suggestions that added tremendous value to our business. We look forward to continuing our collaboration with them in future projects.
                  </p>
                  <h5> Rohan Patel </h5>
                
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Client;
