import React from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import { ImLocation } from "react-icons/im";
import {  FaPhone , FaWhatsapp, FaAngleRight,FaFilePdf} from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import logo from "../../img/core-img/logo.png"
import { Link, NavLink, useParams } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import {  featureUrl, imgUrl, overviewUrl, url } from '../../data/Data';
import { useEffect } from 'react';
import ContactForm from '../ContactForm';


const Location = () => {
    const [location, setLocation] = useState([])
    const [locationImg, setLocationImg] = useState([])
    const [locationdata, setLocationData] = useState([])
    const {id} = useParams()
    const [featuredData, setFeaturedData] = useState([]);

  const getFeaturedData = () => {
    axios.get(`${url}${featureUrl}`).then((res) => {
      if (res.data.status === 200) {
      setFeaturedData(res?.data?.data);
      }
    });
  };
    
    const getFloor = () => {
      axios.get(`${url}${overviewUrl}/${id}/location`)
      .then((res) => {
        if (res.data.status === 200) {
          setLocation(res?.data?.data?.project)
          setLocationData(res?.data?.data?.location)
          const locationmap = res?.data?.data?.location;
          if (locationmap && Array.isArray(locationmap.images) && locationmap.images.length > 0) {
            setLocationImg(locationmap.images);
          }
          }
         
      })
    }
    
    useEffect(() => {
        getFloor();
        getFeaturedData();
    }, [id])


  return (
    <>
    <Caller/>
   <Scroll/>
   <div className="wrapper">
       <Link to="/" className="logo">
         <img src={logo} alt=""/>
       </Link>
       <input type="checkbox" id="menu-bar" />
       <label htmlFor="menu-bar" className="menu-button-container">
         <div className="menu-button"></div>
       </label>
       <nav className="navbar ">
         <ul >
           <li >
             <NavLink to={`/project/detail/${id}`} activeClassName="active">Overview</NavLink>
           </li>
           <li>
             <NavLink to={`/project/pagedetail/${location.id}/floorplan`} activeClassName="active">Floor Plan</NavLink>
           </li>
           <li>
             <NavLink to={`/project/pagedetail/${location.id}/price`} activeClassName="active">Price List </NavLink>
           </li>
           <li>
             <NavLink to={`/project/pagedetail/${location.id}/layout-plan`} activeClassName="active">Layout Plan </NavLink>
           </li>
           <li><NavLink to="#" activeClassName="active">Location map</NavLink></li>
         </ul>
       </nav>
     </div>
     <section className="listings-content-wrapper section-padding-100">
     <div className="listings-top-meta d-flex justify-content-between" style={{margin: "20px", marginTop: "50px"}}>
     <div className="view-area d-flex align-items-center" style={{minWidth: "20vw"}}>
<span className='d-flex align-items-center' style={{minWidth: "20vw", flexWrap: "wrap"}}><Link to="/">Home</Link> <FaAngleRight/> 
<span> {location ? location.name : ""} </span>
<FaAngleRight/> Location Map
</span>
<div className="grid_view ml-15"><a href="#" className="active"><i className="fa fa-th" aria-hidden="true"></i></a></div>
<div className="list_view ml-15"><a href="#"><i className="fa fa-th-list" aria-hidden="true"></i></a></div>
</div>
</div>
<div className="container">
<div className="row justify-content-center">

<div className="col-12 col-lg-8">
<div className="listings-content">
 <div className="card overview" style={{marginTop: "-50px"}}>
<h5>Location Map Of {location ? location.name : ""}</h5>
 </div>
<div className="card floorPlan">
    <p>{locationdata.meta_description}</p>
   <div className="floor-img-area">
   {locationImg.length > 0  ? (
    
    locationImg.map((result) => {
      return (
        result.type==0?
          <div className="floor-img" key={result.id}>
  <img  src={imgUrl + result.file_name} alt="Floor Plan" />

  </div>:
   <div className="floor-img" key={result.id}>
   <a href={imgUrl + result.file_name} className='text-danger' target='_blank' style={{fontSize:'16px'}}><FaFilePdf />Location Layout</a>
 
   </div>
      )
        })
    
) : ( <p>Location Map is not available</p> ) }
   
</div>
</div>

</div>
</div>
<div className="col-12 col-md-6 col-lg-4">

<div className="contact-realtor-wrapper">
<ContactForm/>
</div>

</div>
</div>
</div>
</section>
     <footer className="footer-area section-padding-100-0 bg-img gradient-background-overlay">

<div className="main-footer-area">
<div className="container">
<div className="row ">

<div className="col-12 col-sm-6 col-xl-3 about-first">
<div className="footer-widget-area mb-100">

<div className="footer-logo ">
<img src={logo} alt="Logo"/>
</div>
<p>At GrowMax, we firmly believe that collaboration is the key to success. We understand that no challenge is insurmountable when we join forces and work together.</p>
</div>
</div>


<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Useful Links</h6>
</div>

<ul className="useful-links-nav">
 <li>
 <Link to={`/project/detail/${id}`}>Overview</Link>
           </li>
           <li>
           <Link to={`/project/pagedetail/${location.id}/floorplan`}>Floor Plan</Link>
           </li>
           <li>
           <Link to={`/project/pagedetail/${location.id}/price`}>Price List </Link>
           </li>
           <li>
             <Link to={`/project/pagedetail/${location.id}/layout-plan`}>Layout Plan </Link>
           </li>
           <li><Link to="#">Location Map</Link></li>
</ul>
</div>
</div>

<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Featured Projects</h6>
</div>
{featuredData && featuredData.map((result) => {
  return (
<ul className="useful-links-nav" key={result.id}>
<li><Link to={`/project/detail/${result.id}`}>{result && result.name}</Link></li>
</ul>
  )
})}
</div>
</div>
<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Contact</h6>
</div>

<ul className="useful-links-nav " style={{paddingLeft: "0"}}>
<li><a href="tel: +91 8750994488"><FaPhone/> +91 8750994488</a></li>
<li><a href="#"><ImLocation/> LG 8, Rise Plam Street Noida Ext, Uttar Pradesh 201307</a></li>
<li><a href="mailto:sales@growmaxrealty.in"><span><MdEmail/> </span> sales@growmaxrealty.in</a></li>
</ul>
</div>
</div>

</div>
</div>
</div>

<div className="copywrite-text d-flex align-items-center justify-content-center">
<p>
Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> 2023 All rights reserved By GrowMax India Realtors
</p>
</div>
<div className="last-container">
     <div className="log">
   <a href="https://wa.me/+918750994488?text=Thank for contacting GrowMax..........." rel="noreferrer"  target='_blank'><FaWhatsapp/> <span>Chat Now</span> </a>
   </div>
   <div className="log" style={{backgroundColor: "rgb(64, 184, 242)"}}>
   <a href="tel: +91 8750994488"><FaPhone/> Call Now</a>
   </div>
   </div>
</footer>
   </>
  )
}

export default Location