import React, { useEffect, useState } from "react";
import "../components/Navbar.css";
import logo from "../img/core-img/logo.png";
import { FaAngleDown, } from "react-icons/fa";
import { Link } from "react-router-dom";
import { url, cityUrl, builderUrl } from "../data/Data";
import axios from "axios";
import { ImLocation } from "react-icons/im";
import builder from "../img/bg-img/builder.png"

const Navbar = () => {

const [cityList, setCityList] = useState([])
const [builderList, setBuilderList] = useState([])


const getList = () => {
  axios.get(`${url}${cityUrl}`).then((res) => {
    setCityList(res?.data?.data)
  })
}
const getBuilderUrl = () => {
  axios.get(`${url}${builderUrl}`).then((res) => {
    if (res.data.status === 200) {
    setBuilderList(res?.data?.data)
    }
  })
}


useEffect(() => {
  getList();
  getBuilderUrl();
}, [])

  return (
    <>
      <div className="wrapper">
        <Link to="/" className="logo">
          <img src={logo} alt=""/>
        </Link>
        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar" className="menu-button-container">
          <div className="menu-button"></div>
        </label>
        <nav className="navbar ">
          <ul>
            <li >
              <Link to="#">City <FaAngleDown/></Link>
              <ul>
            {cityList && cityList.map((result) => {
              return (
                <li key={result.id}>
                  <Link to={`/city/${result.slug}/${result.id}`}><ImLocation/>  {result.city_name}</Link>
                </li>
                )
              })}
                
              </ul>
            </li>
            <li>
              <Link to="#">Builder <FaAngleDown/></Link>
              <ul>
                {builderList && builderList.map((result) => (
                <li key={result.id}>
                  <Link to={`/builder/${result.slug}/${result.id}`}><img src={builder} alt="" style={{width: "18px", height: "18px"}}/> {result.name}</Link>
                </li>
                ))}

              </ul>
            </li>
            <li>
              <Link to="#">Projects <FaAngleDown/></Link>
              <ul>
                <li>
                  <Link to="/featured">Featured</Link>
                </li>
               
                <li>
                  <Link to="/residential">
                    Residential
                  </Link>
                </li>
              
                <li>
                  <Link to="/commercial">
                    Commercial
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="#">About us <FaAngleDown/></Link>
              <ul>
                <li>
                  <Link to="/about">About Growmax</Link>
                </li>
              
                <li>
                  <Link to="/mission">Mission & Vission</Link>
                </li>
               
                <li>
                  <Link to="/aboutdetail">Who We are</Link>
                </li>
              </ul>
            </li>
            <li><Link to="/blogs">Blogs</Link></li>
            <li><Link to="/client">Client's Speak</Link></li>
            <li><Link to="/contact">Contact us</Link></li>
          </ul>
        </nav>
      </div>
    </>
  );
};

export default Navbar;
