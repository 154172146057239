import React, {useEffect, useState} from 'react'
import { FaAngleUp } from "react-icons/fa";


const Scroll = () => {
    const [visible, setVisible] = useState(false)
    const handleScroll = () => {
        const scrollTop = window.pageYOffset;
        if (scrollTop > 350) {
          setVisible(true);
          } else {
            setVisible(false);
      }
    }
    
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
          });
      }
    
    useEffect(() => {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    }, [])

  return (
    <>
    <div className={`scroll ${visible ? 'visible' : ''}`} onClick={scrollToTop}>
     <FaAngleUp/>
    </div>
    
    </>
  )
}

export default Scroll