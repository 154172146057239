import React, { useEffect, useRef, useState } from "react";
import "../components/style.css";
import img1 from "../img/bg-img/feature1.jpg";
import { ImLocation, ImEnlarge } from "react-icons/im";
import { FaBed, FaArrowRight } from "react-icons/fa";
import tata from "../img/bg-img/tata-logo.png";
import birla from "../img/bg-img/birla.jpg";
import godrej from "../img/bg-img/Godrej.jpg";
import adani from "../img/bg-img/adani.jpg";
import mahendra from "../img/bg-img/mahendra.jpg";
import dlf from "../img/bg-img/dlf.jpg";
import lodha from "../img/bg-img/lodha.jpg";
import suncity from "../img/bg-img/suncity.jpg";
import ats from "../img/bg-img/ats.jpg";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Scroll from "./Scroll";
import Caller from "./Caller";
import {url, dealsUrl, commercialUrl, residentialUrl, blogUrl, eventUrl, imageUrl, imgUrl, imgUrl2, mediaUrl, mediaImageUrl } from "../data/Data";
import axios from "axios";
import { Link } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import  { FreeMode, Pagination, Navigation } from "swiper";
import Slider from "./Slider";
import Testimonial from "./Testimonial";


const Home = () => {
  const [changeTab, setChangeTab] = useState(1);
  const [deals, setDeals] = useState([]);
  const [commercialData, setCommercialData] = useState([])
  const [residentialData, setResidentialData] = useState([])
  const [blogData, setBlogData] = useState([])
  const [eventData, setEventData] = useState([])
  const [media, setMedia] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [selectedResidentialFilter, setSelectedResidentialFilter] = useState('all');


  const handleFilterClick = (filter) => {
    setSelectedFilter(filter);
  };

  const filteredCommercialData = commercialData.filter((result) => {
    if (selectedFilter === 'all') {
      return true;
    }
    if (selectedFilter === 'ready-to-move') {
      return result.sale_type === 'ready-to-move';
    }
    if (selectedFilter === 'new-launches') {
      return result.sale_type === 'new-launches';
    }
    if (selectedFilter === 'preleased-properties') {
      return result.sale_type === 'preleased-properties';
    }
    if (selectedFilter === 'assured-returns') {
      return result.sale_type === 'preleased-properties';
    }
    if (selectedFilter === 'society-commercial') {
      return result.sale_type === 'society-commercial';
    }
     
   
    return true;
  });


  const handleResidentialFilterClick = (filter) => {
    setSelectedResidentialFilter(filter);
  };

  const filteredResidentialData = residentialData.filter((result) => {
    if(selectedResidentialFilter === 'all'){
      return true;
    }
    if (selectedResidentialFilter === 'ready-to-move') {
      return result.sale_type === 'ready-to-move';
    }
    if (selectedResidentialFilter === 'new-launches') {
      return result.sale_type === 'new-launches';
    }
     if (selectedResidentialFilter === 'nearning-possession') {
      return result.sale_type === 'nearning-possession';
    } if (selectedResidentialFilter === 'premium-projects') {
      return result.sale_type === 'premium-projects';
    } 
   
    return true;
  });

  const toggleTab = (index) => {
    setChangeTab(index);
  };

  const getDeals = () => {
    axios.get(`${url}${dealsUrl}`).then((res) => {
      setDeals(res?.data?.data);
    });
  };

const getCommercial = () => {
  axios.get(`${url}${commercialUrl}`).then((res) => {
    if (res.data.status === 200) {
    setCommercialData(res?.data?.data);
    
    }
  })
}
const getResidential = () => {
  axios.get(`${url}${residentialUrl}`).then((res) => {
    if (res.data.status === 200) {
    setResidentialData(res?.data?.data);
    }
  })
}
const getBlog = () => {
  axios.get(`${url}${blogUrl}`).then((res) => {
    if (res.data.status === 200) {
    setBlogData(res?.data?.data?.data);
    }
  })
}

const getEvent = () => {
  axios.get(`${url}${eventUrl}`).then((res) => {
    if (res.data.status === 200) {
    setEventData(res?.data?.data)
    }
  })
}

const getMedia = () => {
  axios.get(`${url}${mediaUrl}`).then((res) => {
    if (res.data.status === 200) {
    setMedia(res?.data?.data)
    }
  })
}

  useEffect(() => {
    getDeals();
    getCommercial();
    getResidential();
    getBlog();
    getEvent();
    getMedia();
    setTimeout(() => {
      setIsLoading(false)
    }, 500);
  }, []);

  const truncate = (text, maxLength) => {
    if (text == null) {
      return null;
    } else if (text.length > maxLength) {
      const words = text.split(" ");
      return words.slice(0, maxLength).join(" ");
    } else {
      return text;
    }
  };

  function createMarkup(content) {
    const sanitizedContent = content.replace(/<p>&nbsp;<\/p>/g, '');
    return { __html: sanitizedContent };
  }
  const swiperRef1 = useRef();
  const swiperRef2 = useRef();
  const swiperRef3 = useRef();
  const swiperRef4 = useRef();
  const swiperRef6 = useRef();

  // Calculting Maximum and Minimum BHK
const getMinMaxBhk = (space) => {
  const bhkResult = space.map((spaceItem) => parseInt(spaceItem.bhk));
  const filteredBhk = bhkResult.filter((bhk) => bhk !== null && !isNaN(bhk));

  if (filteredBhk.length === 0) {
    return null;
  }

  if (filteredBhk.length === 1) {
    return filteredBhk[0];
  }

  const minBhk = Math.min(...filteredBhk);
  const maxBhk = Math.max(...filteredBhk);
  return `${minBhk} / ${maxBhk} `;
};

// Calculting Maximum and Minimum Area
const getMinMaxSize = (space) => {
  const areaValue = space.map((spaceItem) => parseInt(spaceItem.size));
  const filteredSize = areaValue.filter((size) => size !== null);
  
  if (filteredSize.length === 0) {
    return { size: "" };
  } else if (filteredSize.length === 1) {
    return { size: filteredSize[0] };
  } else {
    const minSize = Math.min(...filteredSize);
    const maxSize = Math.max(...filteredSize);
    return { minSize, maxSize };
  }
};

// Calculting Maximum and Minimum Price
const getMinMaxPrice = (space) => {
  const prices = space.map((spaceItem) => spaceItem.price);
  const filteredPrice = prices.filter((price) => price !== null);
  
  if (filteredPrice.length === 0) {
    return { price: "" };
  } else if (filteredPrice.length === 1) {
    return { price: filteredPrice[0] };
  } else {
    const minPrice = Math.min(...filteredPrice);
    const maxPrice = Math.max(...filteredPrice);
    return { minPrice, maxPrice };
  }
};
 
  

  return (
    <React.Fragment>
    {isLoading ?
    <div className="spinner">
    <ColorRing 
      visible={true}
      height="80"
      width="80"
      ariaLabel="blocks-loading"
      wrapperStyle={{}}
      wrapperClass="blocks-wrapper"
      colors={['#ed2024', '#ed2024', '#ed2024', '#ed2024', '#ed2024']}
    />
    </div>
    :
    <div>
      <Caller />
      <Scroll />
      <Navbar />
      <Slider/>
       
       {/* --------------------------------------Commercial Project------------------------------------- */}
      <section className="featured-properties-area section-padding-100-50 commercial">
      <div className="container">
          <div className="row heading-row">
            <div className="col-12">
              <div className="section-heading wow fadeInUp">
                <h2>Commercial Projects</h2>
              </div>
            </div>
          </div>
          <div className="d-flex filter-btns">
            <span
            className={`front text ${selectedFilter === 'all' ? 'active' : ''}`}
            onClick={() => handleFilterClick('all')}
            >All</span>
                <span className={`front text ${
            selectedFilter === 'new-launches' ? 'active' : ''
          }`}
          onClick={() => handleFilterClick('new-launches')}>New Launches
                </span>
                <span className={`front text ${
            selectedFilter === 'ready-to-move' ? 'active' : ''
          }`}
          onClick={() => handleFilterClick('ready-to-move')}
        >Ready To Move
                </span>
                <span className={`front text ${
            selectedFilter === 'preleased-properties' ? 'active' : ''
          }`}
          onClick={() => handleFilterClick('preleased-properties')}
        > Preleased Properties 
                </span> 
                <span className={`front text ${
            selectedFilter === 'assured-returns' ? 'active' : ''
          }`}
          onClick={() => handleFilterClick('assured-returns')}> Assured Returns
                </span>
                <span  className={`front text ${
            selectedFilter === 'society-commercial' ? 'active' : ''
          }`}
          onClick={() => handleFilterClick('society-commercial')}> Society Commercial
                </span>
                
                </div>
          <div className="row ">
          
          <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef1.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >
         
           
            {selectedFilter && filteredCommercialData.map((result) => {
              const { minPrice, maxPrice, price } = getMinMaxPrice(result.space);
              const { minSize, maxSize, size } = getMinMaxSize(result.space);
              return (
<div className="col-12 col-md-6 col-xl-4" >
<SwiperSlide key={result.id}>
<Link to={`/project/detail/${result.id}`}>
  <div className="ps-1 pe-1">
                  <div
                    className="single-featured-property mb-50 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="property-thumb">
                      {result.projectimage.slice(0, 1).map((i) => (
                        <img
                          key={i.id}
                          src={
                            imgUrl + 
                            i.proimage
                          }
                          alt=""
                        />
                      ))}
                       {result.sale_type && (
                      <div className="tag">
                      <span>{result && result.sale_type}</span>
                          </div>
                      )}
                     
                   
                    </div>
                    <div className="property-content">
                      <h5>{result.name ? result.name : ""}</h5>
                      <p className="location">{result.developerinfo ? result.developerinfo.name : ""}</p>
                      <div className="property-meta-data d-flex listing">
                        <div className="space" style={{color: "#000"}}>
                          <ImEnlarge  />
                          <span>{minSize && maxSize ? ` ${minSize} - ${maxSize} Sqft` : `${size} sqft`}</span>
                        </div>
                        <div className="list-price highlight">
                        <p>
                          ₹{" "}
                          <span>
                            {minPrice && maxPrice
                              ? `${
                                  minPrice >= 10000000
                                    ? (minPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (minPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                } - ${
                                  maxPrice >= 10000000
                                    ? (maxPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (maxPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                }`
                              : price >= 10000000
                              ? (price / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (price / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " L"}
                          </span>
                          *
                        </p>
                      </div>
                      </div>
                      <div className="view-location">
                        <p className="location location1" style={{height: "7.6vh"}}>
                          <ImLocation />  {result.locationinfo && result.locationinfo.name}, {result.cityinfo && result.cityinfo.city_name}
                        </p>
                        <Link to={`/project/detail/${result.id}`} style={{ marginTop: "-5px" }}>
                          <FaArrowRight />
                        </Link>
                      </div>
                    </div>
                  </div>
                  </div>
                 </Link>
                 </SwiperSlide>
                </div>
                 
              );
            })}
            </Swiper>
           </div>
          <div className="row">
          
            <Link
              to="/commercial"
              className="btn south-btn wow fadeInUp"
              data-wow-delay="500ms"
            >
              View more
            </Link>
          </div>
        </div>
      </section>
       {/* --------------------------------------End------------------------------------- */}
      
       {/* --------------------------------------Hot Deals------------------------------------- */}
      <section className="south-editor-area featured-properties-area section-padding" style={{backgroundImage: 'linear-gradient(to right, #b8c2e3, #E2E2E2)'}}>
        <div className="row heading-row deals">
          <div className="col-12">
            <div className="section-heading wow fadeInUp ">
              <h2>Hot Deals</h2>
            </div>
          </div>
        </div>
        {deals && deals.map((ind) => {
           const { minPrice, maxPrice, price } = getMinMaxPrice(ind.space);
           const { minSize, maxSize, size } = getMinMaxSize(ind.space);
          return (
            <div className="hot-deals south-editor-area d-flex" key={ind.id}>
              <div className="editor-content-area">
                <div
                  className="section-heading wow fadeInUp"
                  data-wow-delay="250ms"
                >
                  <h2 style={{border: "none"}}>{ind.name ? ind.name : ""}</h2>
                  <p className="location2">
                    <ImLocation />
                    {ind.locationinfo && ind.locationinfo.name},  {ind.cityinfo && ind.cityinfo.city_name}
                  </p>
                </div>
                {ind.description && (
                <p className="deals-description"
                  dangerouslySetInnerHTML={createMarkup(
                    truncate(ind.description, 26) 
                  )}
                ></p>
                )}
                <div className="space">
                  <ImEnlarge />
                  <span>
                    {" "}
                    {minSize && maxSize ? `${minSize} - ${maxSize}` : size}
                  </span>
                </div>
                <div className="list-price1">
                  <p>
                    ₹
                    <span>
                      {minPrice && maxPrice
                        ? `${
                            minPrice >= 10000000
                              ? (minPrice / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (minPrice / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + "L"
                          } - ${
                            maxPrice >= 10000000
                              ? (maxPrice / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (maxPrice / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + "L"
                          }`
                        : price >= 10000000
                        ? (price / 10000000).toLocaleString("en-In", {
                            maximumFractionDigits: 2,
                          }) + " Cr"
                        : (price / 100000).toLocaleString("en-In", {
                            maximumFractionDigits: 2,
                          }) + "L"}
                    </span>
                    *
                  </p>
                </div>
                <Link
                  to={`/project/detail/${ind.id}`}
                  className="btn south-btn wow fadeInUp"
                  data-wow-delay="500ms"
                  style={{borderRadius: "10px"}}
                >
                  See Details
                </Link>
              </div>

              <div className="editor-thumbnail">
                <Link to={`/project/detail/${ind.id}`}>
                  <div className="border-shadow">
                <figure>
                  {ind.projectimage.map((j) => (
                    <img
                    key={j.id}
                      src={
                       imgUrl +
                        j.proimage
                      }
                      alt=""
                    />
                  ))}
                  <div className="band"></div>
                </figure>
                </div>
                </Link>
              </div>
            </div>
          );
        })}
      </section>
      {/* --------------------------------------End------------------------------------- */}
      
      {/* --------------------------------------Residential Project------------------------------------- */}
      <section className="featured-properties-area section-padding-10-50" style={{marginTop: "35px"}}>
        <div className="container">
          <div className="row heading-row">
            <div className="col-12">
              <div className="section-heading wow fadeInUp">
                <h2>Residential Projects</h2>
              </div>
            </div>
          </div>
          <div className="d-flex filter-btns">
          <span
            className={`front text ${selectedResidentialFilter === 'all' ? 'active' : ''}`}
            onClick={() => handleResidentialFilterClick('all')}
            >All</span>
                <span className={`front text ${
            selectedResidentialFilter === 'new-launches' ? 'active' : ''
          }`}
          onClick={() => handleResidentialFilterClick('new-launches')}>New Launches
                </span>
                <span 
                className={`front text ${
                  selectedResidentialFilter === 'nearning-possession' ? 'active' : ''
                }`}
                onClick={() => handleResidentialFilterClick('nearning-possession')}
                >Nearing Possession</span>
                <span className={`front text ${
            selectedResidentialFilter === 'ready-to-move' ? 'active' : ''
          }`}
          onClick={() => handleResidentialFilterClick('ready-to-move')}>Ready To Move
                </span>
                <span className={`front text ${
            selectedResidentialFilter === 'premium-projects' ? 'active' : ''
          }`}
          onClick={() => handleResidentialFilterClick('premium-projects')}>
                 Premium Projects
                </span>
                
                </div>
          <div className="row">
          <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef2.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >
           
               {selectedResidentialFilter &&  filteredResidentialData.map((res) => {
              const { minPrice, maxPrice, price } = getMinMaxPrice(res.space);
              const { minSize, maxSize, size } = getMinMaxSize(res.space);
              const bhkRange = getMinMaxBhk(res.space);
              return (
               
                <div className="col-12 col-md-6 col-xl-4" >
                  <SwiperSlide key={res.id}>
                  <Link to={`/project/detail/${res.id}`}>
                  <div className="ps-1 pe-1">
              <div

                className="single-featured-property mb-50 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="property-thumb">
                  {res.projectimage.slice(0, 1).map((a) => (
                  <img key={a.id} src={imgUrl + a.proimage} alt="" />
                  ))}
                   {
                   res.sale_type && (
                    <div className="tag">
                    <span>{res && res.sale_type}</span>
                  </div>
                   )
                  }
                </div>

               
                <div className="property-content">
                  <h5>{res.name}</h5>
                  <p className="location">{res.developerinfo.name}</p>
                  <div className="property-meta-data d-flex listing">
                    <div>
                    <div className="garage" style={{ marginBottom: " 4px", color: "#000" }}>
                      <FaBed />
                      {getMinMaxBhk(res.space) && <span>{getMinMaxBhk(res.space)} BHK</span>}
                    </div>
                    <div className="space" style={{color: "#000"}}>
                      <ImEnlarge />
                      <span> {minSize && maxSize ? ` ${minSize} - ${maxSize} Sqft` : `${size} sqft`}</span>
                    </div>
                    </div>
                    <div className="list-price highlight">
                        <p>
                          ₹{" "}
                          <span>
                            {minPrice && maxPrice
                              ? `${
                                  minPrice >= 10000000
                                    ? (minPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (minPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                } - ${
                                  maxPrice >= 10000000
                                    ? (maxPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (maxPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                }`
                              : price >= 10000000
                              ? (price / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (price / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " L"}
                          </span>
                          *
                        </p>
                      </div>
                  </div>
                  <div className="view-location">
                    <p className="location location1" style={{height: "7vh"}}>
                      <ImLocation />  {res.locationinfo && res.locationinfo.name},  {res.cityinfo && res.cityinfo.city_name}
                    </p>
                    <Link to={`/project/detail/${res.id}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              </div>
              </Link>
             </SwiperSlide>
             </div>
                )
              })}  
          </Swiper>
         
          </div>
          <div className="row">
         
            <Link
              to="/residential"
              className="btn south-btn wow fadeInUp"
              data-wow-delay="500ms"
            >
              View more
            </Link>
          </div>
        
        </div>
      </section>
      {/* --------------------------------------End------------------------------------- */}
      
      {/* --------------------------------------Banner------------------------------------- */}
      <section className="call-to-action-area bg-fixed bg-overlay-gray">
        <div className="container h-100">
          <div className="row align-items-center h-100">
            <div className="col-12">
              <div className="cta-content text-center">
                <h2 className="wow fadeInUp" data-wow-delay="300ms">
                  Are you looking for a place to rent?
                </h2>
                <h6 className="wow fadeInUp" data-wow-delay="400ms">
                LG 8, Rise Plam Street Noida Ext, Uttar Pradesh 201307
                </h6>
                <a
                  href="#"
                  className="btn south-btn mt-50 wow fadeInUp"
                  data-wow-delay="500ms"
                >
                  Search
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
         {/* --------------------------------------End------------------------------------- */}
         
       {/* --------------------------------------Blog------------------------------------- */}
      <section className="featured-properties-area section-padding-100-50">
        <div className="container">
          <div className="row heading-row" style={{height: "8vh"}}>
            <div className="col-12">
              <div className="section-heading wow fadeInUp">
                <h2>Blog</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef3.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >
            {blogData && blogData.map((z) => {
              const date = z.created_at;
              const newdate = new Date(date);
              const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;

              return (
            <div className="col-12 col-md-6 col-xl-4" >
               <SwiperSlide  key={z.slug}>
                <Link to={`/blog/detail/${z.slug}`}>
                <div className="ps-1 pe-1">
              <div
                className="single-featured-property mb-50 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="property-thumb">
                {blogData.coverimage ? (
                    <img src={imgUrl + blogData.coverimage} alt="" />
                  ) : (
                  <img src={img1} alt="" />
                  )}
                </div>

                <div className="property-content">
                  <h5>{truncate(z.title && z.title, 6) + `...`}</h5>
                  <p className="location">{formattedDate}</p>
                  <p dangerouslySetInnerHTML={createMarkup(truncate(z.description, 10) + "...")}></p>
                  <div className="view-location">
                    <Link to={`/blog/detail/${z.slug}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />{" "}
                    </Link>
                  </div>
                </div>
              </div>
              </div>
            </Link>
              </SwiperSlide>
            </div>
              )
            })}
              </Swiper>
          </div>
          <div className="row">
            <Link
              to="/blogs"
              className="btn south-btn wow fadeInUp"
              data-wow-delay="500ms"
            >
              View more
            </Link>
          </div>
        </div>
      </section>
      {/* --------------------------------------End------------------------------------- */}
      
      {/* <section className="featured-properties-area section-padding-40-50">
        <div className="container">
          <div className="row heading-row">
            <div className="col-12">
              <div className="section-heading wow fadeInUp">
                <h2>
                  <span className={changeTab === 1 ? "active-color" : ""} onClick={() => toggleTab(1)}>Events</span> /{" "}
                  <span className={changeTab === 2 ? "active-color" : ""} onClick={() => toggleTab(2)}>Media</span>{" "}
                </h2>
              </div>
            </div>
          </div>

          <div className="row">
            <div className={changeTab === 1 ? "events active-tab" : "events"}>
            <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef4.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >
            {eventData.map((result) => {
               const date = result.created_at;
               const newdate = new Date(date);
               const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;
                    return (
                     
                      <div className="col-12 col-md-6 col-xl-4 justify-content-center" key={result.id}>
                       
                <SwiperSlide>
                <div
                  className="single-featured-property mb-50 wow fadeInUp"
                  data-wow-delay="400ms"
                >
                  <Link to={`/event/detail/${result.id}`}>
                  <div className="property-thumb">

                    <img src={imageUrl + imgUrl2 + result.eventcoverimage.file_name} alt="Gallery" />
                    <p className="events-heading">{result.event_name}</p>
                    <div className="date">
                      <span style={{fontSize: "18px"}}>{formattedDate}</span>
                    </div>
                    <div className="shadow"></div>
                  </div>
                  </Link>
                </div>
                </SwiperSlide>
               
              </div>
             
                )
              })}
              </Swiper>
          </div>
          </div>
          <div className="row">
            <div className={changeTab === 2 ? "media active-tab" : "media"}>
            <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef6.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >{
        media && media.map((result) => {
         
          return (
            <div className="col-12 col-md-6 col-xl-4 justify-content-center" key={result.id}>
            <SwiperSlide>
            <div
              className="single-featured-property mb-50 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="property-thumb">
              {
                  result.newsimage.map((i) => (
                    <img key={i.id} src={imageUrl + mediaImageUrl + i.file_name} alt="" />
                  ))
                 }
                   
              </div>

              <div className="property-content">
                <h5>{result ? result.title : ""}</h5>
                {
                  result.newsimage.map((i) => {
                    const date = result.created_at;
                    const newdate = new Date(date);
                    const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;
                    return (

                      <p className="location">{formattedDate}</p>
                    )
        })
                }
               
               <p dangerouslySetInnerHTML={createMarkup(truncate(result.description, 8) + "...")}></p>
                <div className="view-location">
                  <a href="#" style={{ marginTop: "-5px" }}>
                    <FaArrowRight />{" "}
                  </a>
                </div>
              </div>
            </div>
            </SwiperSlide>
          </div>
          )
        })
      }
             
            
             
            
</Swiper>
            </div>
          </div>
          <div className="row">
          <div className="swiper-btns">
          <button onClick={() => swiperRef4.current?.slidePrev()}><FaArrowLeft/></button>
          <button onClick={() => swiperRef4.current?.slideNext()}><FaArrowRight/></button>
          </div>
            {changeTab === 1 ? <Link
              to="/events"
              className="btn south-btn wow fadeInUp"
              data-wow-delay="500ms"
            >
              View more
            </Link> :  <Link
              to="/media"
              className="btn south-btn wow fadeInUp"
              data-wow-delay="500ms"
            >
              View more
            </Link>}
          </div>
        </div>
      </section> */}
      
     <Testimonial/>
     
      <div
        className="divider"
        style={{ border: "1px solid rgb(232, 229, 229)" }}
      >
  
      </div>
       {/* --------------------------------------Builder------------------------------------- */}
      <section className="south-testimonials-area section-padding-100 builder">
        <div className="container">
          <div className="row heading-row" style={{height: "7vh"}}>
            <div className="col-12">
              <div
                className="section-heading wow fadeInUp"
                data-wow-delay="250ms"
              >
                <h2>Builder</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="brands">
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={tata} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={ats} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={mahendra} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={birla} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={adani} alt="" />
                </div>
              </div>

              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={godrej} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={dlf} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={lodha} alt="" />
                </div>
              </div>
              <div className="single-testimonial-slide text-center builder-slide">
                <div className="builder-slider">
                  <img src={suncity} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
         {/* --------------------------------------End------------------------------------- */}
      <Footer />
      </div>
            }
    </React.Fragment>
  );
};

export default Home;
