import React, { useEffect, useState } from 'react'
import logo from '../img/core-img/logo.png'
import { ImLocation } from "react-icons/im";
import { FaFacebookF, FaInstagram, FaLinkedin, FaTwitter, FaPhone, FaWhatsapp, } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { url, featureUrl } from "../data/Data";
import axios from "axios";
import { Link } from 'react-router-dom';


const Footer = () => {
  const [featuredData, setFeaturedData] = useState([]);

  const getFeaturedData = () => {
    axios.get(`${url}${featureUrl}`).then((res) => {
      if (res.data.status === 200) {
      setFeaturedData(res?.data?.data);
      }
    });
  };

useEffect(() => {
  getFeaturedData();
}, [])

  return (
    <>
    <footer className="footer-area section-padding-100-0 bg-img gradient-background-overlay">

<div className="main-footer-area">
<div className="container">
<div className="row ">

<div className="col-12 col-sm-6 col-xl-3 about-first">
<div className="footer-widget-area mb-100">

<div className="footer-logo ">
<img src={logo} alt="Logo"/>
</div>
<p>At GrowMax, we firmly believe that collaboration is the key to success. We understand that no challenge is insurmountable when we join forces and work together. </p>
</div>
</div>


<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Useful Links</h6>
</div>

<ul className="useful-links-nav">
<li><Link to="/about">About us</Link></li>
<li><Link to="/blogs">Blogs</Link></li>
{/* <li><Link to="/events">Events</Link></li> */}
<li><Link to="/client">Client's Speak</Link></li>
<li><Link to="/privacy">Privacy Policy</Link></li>
<li><Link to="/terms">Terms & Condition</Link></li>
</ul>
</div>
</div>
{featuredData.length > 0 && (
<div className="col-12 col-sm-6 col-xl-3">

<div className="footer-widget-area mb-100">
<div className="widget-title">
<h6>Featured Projects</h6>
</div>
{featuredData && featuredData.map((result) => {
  return (
<ul className="useful-links-nav" key={result.id}>
<li><Link to={`/project/detail/${result.id}`}>{result && result.name}</Link></li>
</ul>
  )
})}
</div>
  </div>
   )
} 


<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Contact</h6>
</div>

<ul className="useful-links-nav " style={{paddingLeft: "0"}}>
<li><a href="tel: +91 8750994488"><FaPhone/> +91 8750994488</a></li>
<li><a href="#"><ImLocation/> LG 8, Rise Plam Street Noida Ext, Uttar Pradesh 201307</a></li>
<li><a href="mailto:sales@growmaxrealty.in"><span><MdEmail/> </span> sales@growmaxrealty.in</a></li>
</ul>
<div className="social-media">
    <Link to="https://www.facebook.com/GrowMaxIndiaRealtors?mibextid=ZbWKwL" target='_blank' className="facebook"><FaFacebookF/></Link>
    <Link to="#" className="twitter"><FaTwitter/></Link>
    <Link to="#" className="linkedin"><FaLinkedin/></Link>
    <Link to="#" className="instagram"><FaInstagram/></Link>
</div>
</div>
</div>

</div>
</div>
</div>

<div className="copywrite-text d-flex align-items-center justify-content-center">
<p>
Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> 2023 All rights reserved By GrowMax India Realtors
</p>
</div>
    <div className="last-container">
      <div className="log">
    <a href="https://wa.me/+918750994488?text=Thank for contacting GrowMax..........." rel="noreferrer"  target='_blank'><FaWhatsapp style={{fontSize: "20px"}}/> <span>Chat Now</span> </a>
    </div>
    <div className="log" style={{backgroundColor: "rgb(64, 184, 242)"}}>
    <a href="tel: +91 8750994488"><FaPhone/> Call Now</a>
    </div>
    </div>
    </footer>
    </>
  )
}

export default Footer