import React, { useState, useRef, useEffect } from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import { ImLocation, ImEnlarge } from "react-icons/im";
import { FaBed, FaPhone , FaAngleRight, FaArrowRight, FaWhatsapp, FaFacebookF, FaInstagram, FaLinkedin, FaTwitter, } from "react-icons/fa";
import logo from "../../img/core-img/logo.png"
import { Link, useParams, NavLink } from "react-router-dom";
import { MdEmail } from "react-icons/md";
import { url, detailsUrl, imgUrl, featureUrl } from '../../data/Data';
import axios from 'axios';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import  { FreeMode, Pagination, Navigation } from "swiper";
import preleased from "../../img/bg-img/preleased.png";
import assured from "../../img/bg-img/assured.png";
import ContactForm from '../ContactForm';



const ProjectDetails = () => {
  const [details, setDetails] =  useState([])
  const [similarProject, setSimilarProject] = useState([])
  const { id } = useParams()
  const [featuredData, setFeaturedData] = useState([]);

  const getFeaturedData = () => {
    axios.get(`${url}${featureUrl}`).then((res) => {
      if (res.data.status === 200) {
      setFeaturedData(res?.data?.data);
      }
    });
  };

  

  const getDetails = () => {
    axios.get(`${url}${detailsUrl}/${id}`)
      .then((res) => {
        if(res.data.status === 200){
          setDetails(res?.data?.data?.projectdetail);
          setSimilarProject(res?.data?.data?.similarProjects)
        }

      });
  };

 
useEffect(() => {
  getDetails();
  getFeaturedData();
}, [id])

function createMarkup(content) {
  return { __html: content };
}

 // Find the minimum and maximum values for price, size, and BHK
 const getMinMaxPrice = () => {
  const prices = details?.space?.map(item => Number(item.price)) ?? [];
  const filteredPrice = prices.filter((price) => price !== null);
  if (filteredPrice.length === 0) {
    return { price: "" };
  } else if (filteredPrice.length === 1) {
    return { price: filteredPrice[0] };
  } else {
    const minPrice = Math.min(...filteredPrice);
    const maxPrice = Math.max(...filteredPrice);
    return { minPrice, maxPrice };
  }
};
const { minPrice, maxPrice, price } = getMinMaxPrice();
 
const getMinMaxSize = () => {
  const areaValue = details?.space?.map(item => Number(item.size)) ?? [];
  const filteredSize = areaValue.filter((space) => space !== null);
  if (filteredSize.length === 0) {
    return { size: "" }
  }
  else if (filteredSize.length === 1){
    return { size: filteredSize[0] }
  }
  else {
    const minSize = Math.min(...filteredSize);
    const maxSize = Math.max(...filteredSize);
    return { minSize, maxSize };
  }
  }
   const { minSize, maxSize, size } =  getMinMaxSize()


const getMinMaxBhk = () => {
  const bhks = details?.space?.map(item => Number(item?.bhk?.split(' ')[0])) ?? [];
  const filteredBhk = bhks.filter(
    (bhk) => bhk !== null && !isNaN(bhk)
  );

  if (filteredBhk.length === 0) {
    return null;
  }

  if (filteredBhk.length === 1) {
    return filteredBhk[0];
  }

  const minBhk = Math.min(...filteredBhk);
  const maxBhk = Math.max(...filteredBhk);
  return `${minBhk} / ${maxBhk} `;
};

const swiperRef2 = useRef();

  return (
    <>
    class=
    <Caller/>
    <Scroll/>
    <div className="wrapper">
        <Link to="/" className="logo">
          <img src={logo} alt=""/>
        </Link>
        <input type="checkbox" id="menu-bar" />
        <label htmlFor="menu-bar" className="menu-button-container">
          <div className="menu-button"></div>
        </label>
        <nav className="navbar ">
          <ul >
            <li >
              <NavLink activeclassname="active" to="#">Overview</NavLink>
            </li>
            <li>
              <NavLink to={`/project/pagedetail/${details.id}/floorplan`} activeclassname="active" >Floor Plan</NavLink>
            </li>
            <li>
              <NavLink to={`/project/pagedetail/${details.id}/price`} activeclassname="active" >Price List </NavLink>
            </li>
            <li>
              <NavLink to={`/project/pagedetail/${details.id}/layout-plan`}  activeclassname="active" >Layout Plan </NavLink>
            </li>
            <li><NavLink to={`/project/pagedetail/${details.id}/location`} activeclassname="active" >Location map</NavLink></li>
          </ul>
        </nav>
      </div>
      
<div className="listings-top-meta d-flex justify-content-between mb-20 feature-details">
<div className="view-area d-flex align-items-center" style={{minWidth: "20vw"}}>
<span className='d-flex align-items-center' style={{minWidth: "20vw", flexWrap: "wrap"}}><Link to="/">Home</Link> <FaAngleRight/> 
<span> {details ? details.name : ""} </span>
<FaAngleRight/> Overview
</span>
<div className="grid_view ml-15"><a href="#" className="active"><i className="fa fa-th" aria-hidden="true"></i></a></div>
<div className="list_view ml-15"><a href="#"><i className="fa fa-th-list" aria-hidden="true"></i></a></div>
</div>
        </div>
    <section className="listings-content-wrapper section-padding-100">
<div className="container">
<div className="row justify-content-center">
<div className="col-12 col-lg-8">
<div className="listings-content project_details">
<h5>{details ? details.name : ""}</h5>
<p className="location"><ImLocation/> {details.locationinfo ? details.locationinfo.name : ""}, {details.cityinfo ? details.cityinfo.city_name : ""}</p>
  <a href="https://wa.me/+918750994488?text=Thanks for contacting GrowMax..........." rel="noreferrer"  target='_blank' className='continue request'><FaWhatsapp/> <span>continue to chat</span> </a>
<div className="list-price">
<p>
                          ₹{" "}
                          <span>
                            {minPrice && maxPrice
                              ? `${
                                  minPrice >= 10000000
                                    ? (minPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (minPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                } - ${
                                  maxPrice >= 10000000
                                    ? (maxPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (maxPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                }`
                              : price >= 10000000
                              ? (price / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (price / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " L"}
                          </span>
                          *
                        </p>
<h5>
  {details.title ? details.title : ""}
</h5>
<div id="carouselExampleAutoplaying" className="carousel slide overview-slide" data-bs-ride="carousel">
  <div className="carousel-inner overview-slide">
    {details.projectimage && details.projectimage.map((i) => (
    <div className="carousel-item active overview-slide" key={i.id}>
      <img  src={imgUrl +
                        i.proimage} className="d-block w-100" alt="Banner"/>
    </div>
      
      ))}
      
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
</div>
<div className="card overview-part">
  <table>
    <thead>
    <tr>
    <th>Type</th>
    <th>Area (Sq.Ft.)</th>
    <th>Price</th>
    </tr>
    </thead>
    <tbody>
   {
    

    details?.space && details.space.map((r) => {
      const bhk = r.bhk ? parseInt(r.bhk) : "";
   
      const price = parseInt(r.price);
      let formattedPrice = "";

      if (price >= 10000000) {
        formattedPrice = (price / 10000000).toFixed(2) + " cr";
      } else if (price >= 100000) {
        formattedPrice = (price / 100000).toFixed(2) + " L";
      } else {
        formattedPrice = price;
      }
      return (
        
        <tr key={r.id}>
      <td>{bhk ? `${bhk} BHK` : ""}</td>
      <td>{r.size}</td>
      <td>{formattedPrice}</td>
    </tr>
   
      )
    })
   }
     </tbody>
  </table>
</div>
{details.description && (
<div className='d-flex description'>
  <span>Description</span>
  <p dangerouslySetInnerHTML={createMarkup((details.description && details.description))}></p>
</div>
)}
{details.description && (<hr />)}

<div className="aminities">
<span className='plans-name'>Floor Plans</span>
<div className="aminities-list">
 {getMinMaxBhk() && (<span><FaBed className='plan-icon'/> {getMinMaxBhk()} BHK</span>)}
<span><ImEnlarge className='plan-icon'/> {minSize && maxSize ? ` ${minSize} - ${maxSize} Sqft` : `${size} sqft`}</span>
<span className="request-plan">
<Link to="https://wa.me/+918750994488?text=Thanks for contacting GrowMax..........." target='_blank' className='south-btn'>Request Plan</Link>
</span>
</div>
</div>
<hr />
{
  details.assured_return && details.prelead && (
<div className="aminities">
  <div className="plans-name"></div>
<div className="aminities-list">
  {
    details.assured_return && (
      <span className='assured'><img src={assured} alt="Assured" /> 
<p className='assu'>Assured Return</p>
</span>
    )
  }
{
  details.prelead && (
    <span className='preleased'><img src={preleased} alt="Preleased" /> 
<p className='pre'>Preleased</p>
</span>
  )
}

</div>
</div>
  )
}

{details.assured_return && details.prelead && (<hr/>)}

<div className="aminities">
<span className='plans-name'>Amenities</span>
<div className="aminities-list aminities-list2">
{details.aminities && details.aminities.map((result) => {
  return (
    <span key={result.id} style={{width: "20vw"}}>
    <img src={imgUrl + result.aminitiesinfo.icon} alt="Icon" />
    <p key={result.id}>
   {result.aminitiesinfo.name}
    </p>
</span>
  )
})}
</div>
</div>
<hr />
</div>
</div>
<div className="col-12 col-md-6 col-lg-4">
  {details.logo ? (
    <div className="builder-banner">
    <img src={imgUrl +  details?.logo} alt="Logo" />
  </div>
  ) : ""}
  
  {details.uspinfo?.length > 0 && (
  <div className="card top-rated">
  <div className="usp">
        <h4>USP</h4>
        </div>
    {
     details.uspinfo && details.uspinfo.map((result) => {
      return (
        <ul key={result.id}>
      <li>{result.name}</li>
      </ul>
      )
    })
    }
</div>
  
  )}
<div className="contact-realtor-wrapper">
<ContactForm/>
</div>

</div>
</div>
</div>
</section>
{similarProject && similarProject.length > 0 && (
<section className="featured-properties-area section-padding-10-50">
        <div className="container">
          <div className="row heading-row">
            <div className="col-12">
              <div className="section-heading wow fadeInUp">
                <h2>Similar Projects</h2>
              </div>
            </div>
          </div>
          <div className="row">
          <Swiper
           slidesPerView="auto"
        spaceBetween={20}
        freeMode={true}
        navigation={false} 
        pagination={{
          clickable: true,
        }}
        modules={[FreeMode, Pagination, Navigation]}
        onBeforeInit={(swiper) => {
          swiperRef2.current = swiper;
        }}
        className="mySwiper"
        breakpoints={{
          // When window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 20
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // When window width is >= 1024px
          1024: {
            slidesPerView: 4,
            spaceBetween: 20
          }
        }}
      >
            {
             similarProject && similarProject.map((result) => {
               
              const { minPrice, maxPrice, price } = getMinMaxPrice(result.space);
              const { minSize, maxSize, size } = getMinMaxSize(result.space);
              const bhkRange = getMinMaxBhk(result.space);
                return (
            <div className="col-12 col-md-6 col-xl-4 "  >
               <SwiperSlide key={result.id}>
                <Link to={`/project/detail/${result.id}`}>
              <div
                className="single-featured-property mb-50 wow fadeInUp"
                data-wow-delay="100ms"
              >
                <div className="property-thumb">
                  {result.projectimage.slice(0, 1).map((i) => (
                  <img key={i.id} src={imgUrl + i.proimage} alt="Banner" />
                  ))}
                  { result.sale_type && (
                     <div className="tag">
                     <span>{result && result.sale_type}</span>
                   </div>
                  )}
                 
                </div>

              
                <div className="property-content">
                  <h5>
                    {details ? details.name : ""}
                  </h5>
                  <p className="location">{result.developerinfo ? result.developerinfo.name : ""}</p>
                  <div className="property-meta-data d-flex listing">
                    <div>
                    <div className="garage" style={{ marginBottom: " 4px", color: "#000" }}>
                      <FaBed />
                      {getMinMaxBhk(result.space) && <span>{getMinMaxBhk(result.space)} BHK</span>}
                    </div>
                    <div className="space" style={{color: "#000" }}>
                      <ImEnlarge />
                      <span>  {minSize && maxSize ? ` ${minSize} - ${maxSize} Sqft` : `${size} sqft`}</span>
                    </div>
                    </div>
                    <div className="list-price highlight" >
                        <p>
                          ₹{" "}
                          <span>
                            {minPrice && maxPrice
                              ? `${
                                  minPrice >= 10000000
                                    ? (minPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (minPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                } - ${
                                  maxPrice >= 10000000
                                    ? (maxPrice / 10000000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " Cr"
                                    : (maxPrice / 100000).toLocaleString(
                                        "en-In",
                                        {
                                          maximumFractionDigits: 2,
                                        }
                                      ) + " L"
                                }`
                              : price >= 10000000
                              ? (price / 10000000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " Cr"
                              : (price / 100000).toLocaleString("en-In", {
                                  maximumFractionDigits: 2,
                                }) + " L"}
                          </span>
                          *
                        </p>
                      </div>
                  </div>
                  <div className="view-location">
                    <p className="location location1" style={{height: "7vh"}}>
                      <ImLocation /> {result.cityinfo && result.cityinfo.city_name}, {result.locationinfo && result.locationinfo.name}
                    </p>
                    <Link to={`/project/detail/${result.id}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />
                    </Link>
                  </div>
                </div>
              </div>
              </Link>
              </SwiperSlide>
            </div>
  )
})
}
</Swiper>
         
          </div>
          <div className="row">
          </div>
        </div>
      </section>
      )}
      <footer className="footer-area section-padding-100-0 bg-img gradient-background-overlay">

<div className="main-footer-area">
<div className="container">
<div className="row ">

<div className="col-12 col-sm-6 col-xl-3 about-first">
<div className="footer-widget-area mb-100">

<div className="footer-logo ">
<img src={logo} alt="Logo"/>
</div>
<p>At GrowMax, we firmly believe that collaboration is the key to success. We understand that no challenge is insurmountable when we join forces and work together.</p>
</div>
</div>


<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Useful Links</h6>
</div>

<ul className="useful-links-nav">
  <li>
  <Link to="#">Overview</Link>
            </li>
            <li>
            <Link to={`/project/pagedetail/${details.id}/floorplan`}>Floor Plan</Link>
            </li>
            <li>
            <Link to={`/project/pagedetail/${details.id}/price`}>Price List </Link>
            </li>
            <li>
              <Link to={`/project/pagedetail/${details.id}/layout-plan`}>Layout Plan </Link>
            </li>
            <li><Link to={`/project/pagedetail/${details.id}/location`}>Location Map</Link></li>
</ul>
</div>
</div>

{
  featuredData.length > 0 && (
    <div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Featured Projects</h6>
</div>
{featuredData && featuredData.map((result) => {
  return (
<ul className="useful-links-nav" key={result.id}>
<li><Link to={`/project/detail/${result.id}`}>{result && result.name}</Link></li>
</ul>
  )
})}
</div>
</div>
  )
}


<div className="col-12 col-sm-6 col-xl-3">
<div className="footer-widget-area mb-100">

<div className="widget-title">
<h6>Contact</h6>
</div>

<ul className="useful-links-nav " style={{paddingLeft: "0"}}>
<li><a href="tel: +91 8750994488"><FaPhone/> +91 8750994488</a></li>
<li><a href="#"><ImLocation/> LG 8, Rise Plam Street Noida Ext, Uttar Pradesh 201307</a></li>
<li><a href="mailto:sales@growmaxrealty.in"><span><MdEmail/> </span> sales@growmaxrealty.in</a></li>
</ul>
<div className="social-media">
    <Link to="https://www.facebook.com/GrowMaxIndiaRealtors?mibextid=ZbWKwL" target='_blank' className="facebook"><FaFacebookF/></Link>
    <Link to="#" className="twitter"><FaTwitter/></Link>
    <Link to="#" className="linkedin"><FaLinkedin/></Link>
    <Link to="#" className="instagram"><FaInstagram/></Link>
</div>
</div>
</div>

</div>
</div>
</div>

<div className="copywrite-text d-flex align-items-center justify-content-center">
<p>
Copyright &copy;<script data-cfasync="false" src="../../cdn-cgi/scripts/5c5dd728/cloudflare-static/email-decode.min.js"></script><script>document.write(new Date().getFullYear());</script> 2023 All rights reserved By GrowMax India Realtors
</p>
</div>
<div className="last-container">
      <div className="log">
    <a href="https://wa.me/+918750994488?text=Thank for contacting GrowMax..........." rel="noreferrer"  target='_blank'><FaWhatsapp/> <span>Chat Now</span> </a>
    </div>
    <div className="log" style={{backgroundColor: "rgb(64, 184, 242)"}}>
    <a href="tel: +91 8750994488"><FaPhone/> Call Now</a>
    </div>
    </div>
</footer>
    </>
  )
}

export default ProjectDetails