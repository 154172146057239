import React from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from '../../img/bg-img/hero1.jpg'
import img1 from '../../img/bg-img/feature6.jpg'
import { GiCornerFlag } from "react-icons/gi";
import { MdCenterFocusStrong } from "react-icons/md";
import { Fade } from 'react-reveal'

const Mission = () => {
  return (
    <>
     <Caller/>
    <Scroll/>
    <Navbar/>
    <section className="featured">
        <div className="banner">
          <div className="pic1" >
            {/* <img src={"https://growmaxrealty.in/alpha/administration/" + slider.banner_image} alt="banner"/> */}
            <img src={banner} alt="" />
            <h2>About GrowMax</h2>
          </div>
        </div>
      </section>
      <section className="south-editor-area featured-properties-area section-padding-100-50 about" style={{margin: "40px 0"}}>
        <div className="container">
            <div className="mission">
            <Fade left>
                <div className="mission-img">
                    <img src={img1} alt="Mission" />
                </div>
                <p><GiCornerFlag className='mission-icon'/> <h3>Our Mission</h3> Our mission is to be the premier provider of exceptional construction services, delivering projects of the highest quality and exceeding our clients' expectations. We are dedicated to fostering enduring partnerships with our clients, built on trust, integrity, and a shared commitment to excellence. By leveraging our expertise, innovative approach, and unwavering attention to detail, we aim to create spaces that inspire, uplift, and stand the test of time. Our mission is to leave a lasting impact on the built environment, positively contributing to the communities we serve and shaping a better future for generations to come.</p>
               
            </Fade>
            </div>
            <div className="mission vission">
            <Fade right>
                <p><MdCenterFocusStrong className='mission-icon'/> <h3>Our Vission</h3>Our vision is to be a recognized leader in the construction industry, known for our exceptional quality, innovation, and client satisfaction. We strive to continuously raise the bar and set new standards for excellence in every project we undertake. By embracing cutting-edge technologies, sustainable practices, and a forward-thinking mindset, we aim to be at the forefront of industry advancements. Our vision extends beyond construction itself; we envision creating spaces that not only meet the needs of today but also anticipate the demands of tomorrow. Through our unwavering commitment to craftsmanship, integrity, and the pursuit of excellence, we aspire to leave a lasting legacy of impactful, inspiring, and sustainable structures that enrich the lives of individuals and communities.</p>
                <div className="mission-img">
                    <img src={img1} alt="Mission" />
                </div>
            </Fade>
            </div>
             </div>
      </section>
     
    <Footer/>
    </>
  )
}

export default Mission