import React, { useEffect, useState }  from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from '../../img/bg-img/hero1.jpg'
import { FaArrowRight, FaAngleRight } from "react-icons/fa";
import {  url, blogUrl, imgUrl } from "../../data/Data";
import axios from "axios";
import img1 from "../../img/bg-img/feature1.jpg";
import { Link } from 'react-router-dom';

const Blogs = () => {
  const [blogData, setBlogData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalLinks, setTotalLinks] = useState();

  const loadMore = (selectedPage) => {
    setPage(selectedPage);
  };

  const getBlog = () => {
    axios.get(`${url}${blogUrl}?page=${page}`).then((res) => {
      if (res.data.status === 200) {
      setBlogData(res?.data?.data?.data);
      setTotalLinks(res?.data?.data?.last_page)
      }
    })
  }

  useEffect(() => {
    getBlog();
  }, [page])
  const truncate = (text, maxLength) => {
    if (text == null) {
      return null;
    } else if (text.length > maxLength) {
      const words = text.split(" ");
      return words.slice(0, maxLength).join(" ");
    } else {
      return text;
    }
  };

  function createMarkup(content) {
    const sanitizedContent = content.replace(/<p>&nbsp;<\/p>/g, '');
    return { __html: sanitizedContent };
  }

  return (
    <>
    <Caller/>
    <Scroll/>
    <Navbar/>
    <section className="featured">
    <div className="banner">
            <div className="pic1">
                <img src={banner} alt="banner"/>
                <h2>Blogs</h2>
            </div>
    </div>
</section>


<section class="south-blog-area section-padding-100">
<div className="listings-top-meta d-flex justify-content-between" style={{margin: "20px", marginTop: "-15px"}}>
<div className="view-area d-flex align-items-center">
<span><Link to="/">Home</Link> <FaAngleRight/> Blogs</span>
</div>
</div>
<div class="container">
<div className="row">
            {blogData && blogData.map(( result) => {
              const date = result.created_at;
              const newdate = new Date(date);
              const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;

              return (
            <div className="col-12 col-md-6 col-xl-4" key={result.slug}>
              <Link to={`/blog/detail/${result.slug}`}>
              <div className=' details-area'>
              <div
                className="single-featured-property mb-50 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="property-thumb">
                  {blogData.coverimage ? (
                    <img src={imgUrl + blogData.coverimage} alt="" />
                  ) : (
                  <img src={img1} alt="" />
                  )}
                </div>

                <div className="property-content">
                  <h5>{truncate(result.title, 5) + `...`}</h5>
                  <p className="location">{formattedDate}</p>
                  <p dangerouslySetInnerHTML={createMarkup(truncate(result.description, 10) + "...")}></p>
                  <div className="view-location">
                    <Link to={`/blog/detail/${result.slug}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />{" "}
                    </Link>
                  </div>
                </div>
              </div>
              </div>
              </Link>
            </div>
              )
            })}
          </div>
        <div className="row">
    <div className="col-12">
    <div className="south-pagination d-flex justify-content-center">
    <nav aria-label="Page navigation">
    <div className="pagination">
    {[...Array(totalLinks ? totalLinks : 0)].map((_, i) => {
            return (
              <span
                className={` page-item page-link active ${
                  page === i + 1 ? " " : ""
                }`}
                key={i}
                onClick={() => loadMore(i + 1)}
              >
                {i + 1}
              </span>
            );
          })}
    </div>
    </nav>
    </div>
    </div>
    </div>
</div>
</section>
<Footer/>
    </>
  )
}

export default Blogs