import React from 'react'
import { FaPhone, FaWhatsapp } from "react-icons/fa";

const Caller = () => {
  return (
    <>
     <div className="phone-call whats-app">

    <a href="https://wa.me/+918750994488?text=Thanks for contacting GrowMax..........." className='phone' rel="noreferrer"  target='_blank'>
        <FaWhatsapp  style={{marginTop: "12px", fontSize: "22px"}}/>
    </a>
    <div className="phone1"></div>
    <div className="phone2"></div>
    </div>
    <div className="phone-call">
    <a href="tel: +91 8750994488" className='phone'>
        <FaPhone  style={{marginTop: "14px"}}/>
    </a>
    <div className="phone1"></div>
    <div className="phone2"></div>
    </div>
    </>
  )
}

export default Caller