import React, { useState } from "react";
import Scroll from "../Scroll";
import Caller from "../Caller";
import Navbar from "../Navbar";
import Footer from "../Footer";
import { ImLocation } from "react-icons/im";
import banner from "../../img/bg-img/hero1.jpg";
import { FaAngleRight } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedin,
  FaTwitter,
  FaPhone,
} from "react-icons/fa";
import { Link } from "react-router-dom";


const Contact = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');



  const handleSubmit = async (e) => {
    e.preventDefault();

    // Create a data object with the form values
    const data = {
      name,
      email,
      phone,
      desc: description
    };

    try {
     
      const response = await fetch('https://growmaxrealty.in/administration/api/user/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      
      if (response.ok) {
        
        setName('');
        setPhone('');
        setEmail('');
        setDescription('');
      } else {
        
       alert('Failed to Send Message');
      }
    } catch (error) {
    
      alert('Network error:', error);
    }
  };


  return (
    <>
      <Caller />
      <Scroll />
      <Navbar />
      <section className="featured">
        <div className="banner">
          <div className="pic1">
            <img src={banner} alt="banner" />
            <h2>Contact us</h2>
          </div>
        </div>
      </section>
      <section className="south-contact-area section-padding-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="listings-top-meta d-flex justify-content-between mb-20">
                <div className="view-area d-flex align-items-center">
                  <span>
                    <Link to="/">Home</Link> <FaAngleRight /> Contact us
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="content-sidebar">
                <div className="address mt-30">
                  <div className="address-list">
                    <span>
                      <ImLocation />
                    </span>
                    <a href="#">
                      LG 8, Rise Plam Street Noida Ext, Uttar Pradesh 201307
                    </a>
                  </div>
                  <div className="address-list">
                    <span>
                      <FaPhone />
                    </span>
                    <a href="tel: +91 8750994488"> +91 8750994488</a>
                  </div>
                  <div className="address-list">
                    <span>
                      <MdEmail />{" "}
                    </span>
                    <a href="mailto:sales@growmaxrealty.in">
                      sales@growmaxrealty.in
                    </a>
                  </div>
                  <div className="social-media">
                    <Link
                      to="https://www.facebook.com/GrowMaxIndiaRealtors?mibextid=ZbWKwL"
                      className="facebook"
                      target="_blank"
                    >
                      <FaFacebookF />
                    </Link>
                    <a href="#" className="twitter">
                      <FaTwitter />
                    </a>
                    <a href="#" className="linkedin">
                      <FaLinkedin />
                    </a>
                    <a href="#" className="instagram">
                      <FaInstagram />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-8">
              <div className="contact-form">
                <h3>Any Query</h3>
                <form action="#" method="post" onSubmit={handleSubmit}>
                  <div className="form-group">
                
                    <input
                      type="text"
                      className="form-control"
                      name="text"
                      id="contact-name"
                      placeholder="Your Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                 
                    <input
                      type="number"
                      className="form-control"
                      name="number"
                      id="contact-number"
                      placeholder="Your Phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                 
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      id="contact-email"
                      placeholder="Your Email"
                      value={email}
                       onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="form-group">
                            
                    <textarea
                      className="form-control"
                      name="message"
                      id="message"
                      cols="30"
                      rows="10"
                      placeholder="Your Message"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </div>
                  <button type="submit" className="btn south-btn" style={{borderRadius: "5px", maxWidth: "150px"}}>
                    Send Message
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="map-area mb-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d112091.8869493584!2d77.23271676720245!3d28.603632518033724!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce5631312c2f3%3A0x2bf06ebf1e2dc577!2sNoida%2C%20Uttar%20Pradesh%20201307!5e0!3m2!1sen!2sin!4v1684479387014!5m2!1sen!2sin"
                width="100%"
                height="450"
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
