import React, { useEffect, useState } from "react";
import "../components/style.css";
import { bannerUrl, url, imageUrl} from "../data/Data";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";
import  { Pagination, Navigation, Autoplay } from "swiper";


const Slider = () => {
 const [slider, setSlider] = useState([]);

 const getData = () => {
    axios.get(`${url}${bannerUrl}`).then((res) => {
      setSlider(res?.data?.data);
    });
  };


  useEffect(() => {
    getData();
  }, [])

  return (
    <>
     <section className="banner-section">
        <div className="banner">
        <Swiper
          slidesPerView="auto"
          spaceBetween={20}
          freeMode={true}
          navigation={false} 
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper"
      >
    {slider && slider.map((result) => {
      const preloadImage = imageUrl + result.slider_image;
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = preloadImage;
      document.head.appendChild(link);

      // Add the image to the page after preloading
      const addImageToPage = () => {
        const image = new Image();
        image.src = preloadImage;

        // Wait for the image to finish loading
        image.onload = () => {
          // Add the image to the page dynamically
          const container = document.querySelector('.banner');
          const imgElement = document.createElement('img');
          imgElement.src = preloadImage;
          imgElement.alt = 'Banner';
          container.appendChild(imgElement);
        };
      };
      addImageToPage();
            return (
              <SwiperSlide key={result.id} >
          <div className="pic1">
            
      <img src={preloadImage} alt="Banner"/>
  
      </div>
      </SwiperSlide>
            )
          })}
          </Swiper>
        </div>
      </section>
    
    </>
  )
}

export default Slider