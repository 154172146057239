import React, { useEffect, useState } from 'react'
import Masonry, {ResponsiveMasonry} from "react-responsive-masonry"
import Navbar from '../Navbar'
import Footer from '../Footer'
import Caller from '../Caller'
import Scroll from '../Scroll'
import { url, galleryUrl, imageUrl, imgUrl2 } from '../../data/Data'
import axios from 'axios'
import { useParams } from 'react-router-dom'

const Gallery = () => {
const [gallery, setGallery] = useState([])
const {id} = useParams()

const getGallery = () => {
  axios.get(`${url}${galleryUrl}/${id}`)
  .then((res) => {
    setGallery(res?.data?.data)
  })
}

useEffect(() => {
  getGallery();
}, [id])

  return (
    <>
    <Caller/>
    <Scroll/>
    <Navbar/>
    <div className='gallery-section'>
    <div className="row heading-row">
            <div className="col-12">
                  <div className="section-heading wow fadeInUp">
                  <h2>Gallery</h2>
                </div>
            </div>
          </div>
            
    <ResponsiveMasonry
                columnsCountBreakPoints={{350: 1, 750: 2, 900: 3}}
            >
                  <Masonry gutter='20px'>
                  {gallery && gallery.length > 0 && gallery[0].eventimagelist.map((i) => (

                  <img key={i.id} src={imageUrl + imgUrl2 + i.file_name} alt="Gallery" style={{width: "100%", display: "block"}} />
                ))}
               </Masonry>
            </ResponsiveMasonry>
             
               
            </div>
            <Footer/>
    </>
  )
}

export default Gallery