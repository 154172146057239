import React, { useEffect, useState } from 'react'
import Scroll from '../Scroll'
import Caller from '../Caller'
import Navbar from '../Navbar'
import Footer from '../Footer'
import banner from '../../img/bg-img/hero1.jpg'
import { FaArrowRight, FaAngleRight } from "react-icons/fa";
import {  url, mediaListUrl, imageUrl, mediaImageUrl } from "../../data/Data";
import axios from "axios";
import { Link } from 'react-router-dom';

const Media = () => {
  const [mediaData, setMediaData] = useState([]);


  const getMedia = () => {
    axios.get(`${url}${mediaListUrl}`).then((res) => {
      if (res.data.status === 200) {
      setMediaData(res?.data?.data);
      }
    })
  }

  useEffect(() => {
    getMedia();
  }, [])

  const truncate = (text, maxLength) => {
    if (text == null) {
      return null;
    } else if (text.length > maxLength) {
      const words = text.split(" ");
      return words.slice(0, maxLength).join(" ");
    } else {
      return text;
    }
  };

  function createMarkup(content) {
    const sanitizedContent = content.replace(/<p>&nbsp;<\/p>/g, '');
    return { __html: sanitizedContent };
  }
  return (
    <>
    <Scroll/>
    <Caller/>
    <Navbar/>
    <section className="featured">
    <div className="banner">
            <div className="pic1">
                <img src={banner} alt="banner"/>
                <h2>Media</h2>
            </div>
    </div>
</section>
<section class="south-blog-area section-padding-100">
<div className="listings-top-meta d-flex justify-content-between" style={{margin: "20px", marginTop: "-15px"}}>
<div className="view-area d-flex align-items-center">
<span><Link to="/">Home</Link> <FaAngleRight/> Blogs</span>
</div>
</div>
<div class="container">
<div className="row">
            {mediaData && mediaData.map(( result) => {
              const date = result.created_at;
              const newdate = new Date(date);
              const formattedDate = `${newdate.getDate()}/${newdate.getMonth() + 1}/${newdate.getFullYear()}`;

              return (
            <div className="col-12 col-md-6 col-xl-4" key={result.id}>
              <div
                className="single-featured-property mb-50 wow fadeInUp"
                data-wow-delay="400ms"
              >
                <div className="property-thumb">
                 {
                  result.newsimage.map((i) => (
                    <img key={i.id} src={imageUrl + mediaImageUrl + i.file_name} alt="" />
                  ))
                 }
                   
                
                </div>

                <div className="property-content">
                  <h5>{result.title ? result.title : ""}</h5>
                  <p className="location">{formattedDate}</p>
                  <p dangerouslySetInnerHTML={createMarkup(truncate(result.description, 8) + "...")}></p>
                  <div className="view-location">
                    <Link to={`/blog/detail/${result.slug}`} style={{ marginTop: "-5px" }}>
                      <FaArrowRight />{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
              )
            })}
          </div>
       
</div>
</section>
    <Footer/>
    
    </>
  )
}

export default Media