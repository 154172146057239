export const url = "https://growmaxrealty.in/administration/api/user/"
export const imageUrl = "https://growmaxrealty.in/administration/public/"
export const imgUrl = "https://growmaxrealty.in/administration/"


export const mediaImageUrl = "uploads/news/medium/"
export const imgUrl2 = "uploads/event/large/"
export const bannerUrl = "slider";

export const featureUrl = "featured-project"

export const featureDetails = "featured/project-listing"

export const dealsUrl = "hotdeal"

export const commercialUrl = "project/commercial"

export const commercialDetails = "commercial/project-listing"

export const residentialUrl = "project/residential"

export const residentialDetails = "residential/project-listing"

export const blogUrl = "bloglist"

export const cityUrl = "citylist"

export const builderUrl = "builderlist"

export const builderDataUrl = "builder/project"

export const cityDataUrl = "city/project"

export const eventUrl = "event"

export const detailsUrl = "project/detail"

export const blogdetails = "blog/detail"

export const galleryUrl = "event/detail"

export const mediaUrl = "letest-media"

export const mediaListUrl = "medialist"

export const overviewUrl = "project/pagedetail"

